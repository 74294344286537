export default function Page404() {
  return (
    <div>
      <div className="flex justify-center items-center min-h-screen">
        <img
          src="https://raw.githubusercontent.com/0nahid/manufacturer-website-client/main/src/Componnets/Errors/error-404.png"
          alt="error"
        />
      </div>
    </div>
  );
}
