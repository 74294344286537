import { useContext, useState } from "react";
import { BsCloudDownload, BsJournalBookmarkFill } from "react-icons/bs";
import { ImFacebook2, ImGithub, ImLinkedin } from "react-icons/im";
import { InitializeContext } from "../../App";

export default function Portfolio() {
  const { theme } = useContext(InitializeContext);
  // console.log(theme);
  const [pdfview, setPdfview] = useState<string>("view");
  let darkFont: string = theme ? "text-info" : "text-black";
  let socialIconStyle: string = theme
    ? `h-7 w-7 hover:text-info`
    : "h-7 w-7 hover:text-secondary";
  // make an array of front end technologies
  //   console.log(pdfview);

  return (
    <>
      <div className="">
        <div>
          <div className="card card-compact max-w-lg bg-base-100 shadow-xl mx-auto mt-5 h-screen">
            <div className="avatar">
              <div className="mx-auto w-32 md:w-48 mt-10 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                <img src="https://i.ibb.co/Sy3pwsx/me.jpg" alt="Nahid" />
              </div>
            </div>
            <div className="mx-auto text-center mt-5 mb-6">
              <h1 className="text-3xl font-bold">Nahid Hassan Bulbul</h1>
              <p className="text-sm font-bold">Full Stack Web developer</p>
              <p className="">
                {" "}
                <span className={darkFont + " underline mr-1"}>Email:</span>
                <a
                  className="font-bold hover:text-info"
                  href="mailto:nahidhassanbulbul@gmail.com"
                >
                  nahidhassanbulbul@gmail.com
                </a>{" "}
              </p>
              <div className="flex mt-5 justify-center">
                <a
                  href="https://www.facebook.com/hashtagnahid"
                  target="_blank"
                  rel="noreferrer"
                  className="mr-5"
                >
                  <ImFacebook2 className={socialIconStyle} />
                </a>
                <a
                  href="https://www.linkedin.com/in/nahid-hassan-bulbul/"
                  target="_blank"
                  rel="noreferrer"
                  className="mr-5"
                >
                  <ImLinkedin className={socialIconStyle} />
                </a>
                <a
                  href="https://github.com/0nahid/"
                  target="_blank"
                  rel="noreferrer"
                  className="mr-5"
                >
                  <ImGithub className={socialIconStyle} />
                </a>
              </div>
            </div>
            {/* Stat */}
            <h1 className="text-center text-xl font-bold">Stats</h1>
            <div className="stats stats-vertical lg:stats-horizontal  text-center">
              <div className="stat">
                <div className="stat-title">Experience</div>
                <div className="stat-value">3Y</div>
                <div className="stat-desc">Jan 2021 - Present</div>
              </div>
              <div className="stat">
                <div className="stat-title">Projects</div>
                <div className="stat-value">100+</div>
                <div className="stat-desc">↗︎5+ On Progress</div>
              </div>
              <div className="stat">
                <div className="stat-title">Github Repos</div>
                <div className="stat-value">205+</div>
                <div className="stat-desc">↗︎ 90 (14%)</div>
              </div>
            </div>

            {/* CV & other stuff */}
            <div className="btn-group flex justify-center m-5">
              <button
                onClick={() => setPdfview("download")}
                className={
                  pdfview === "download" ? "btn btn-neural " : "btn btn-info  "
                }
              >
                Download
              </button>
              <button
                onClick={() => setPdfview("view")}
                className={
                  pdfview === "view" ? "btn btn-neural " : "btn btn-info  "
                }
              >
                View
              </button>
            </div>
            <div className="text-center mb-10">
              {pdfview === "download" ? (
                <a
                  download={
                    "Nahid Hassan Bulbul - Full Stack Web Developer - Resume.pdf"
                  }
                  href="https://drive.google.com/u/0/uc?id=1WZpIBhVi2LWEiCYk_jb61FgfPKHi0BbL&export=download"
                  className="btn"
                >
                  Download CV
                  <BsCloudDownload className="h-4 w-4 ml-2" />{" "}
                </a>
              ) : (
                <a
                  href="https://drive.google.com/file/d/1WZpIBhVi2LWEiCYk_jb61FgfPKHi0BbL/view?usp=sharing"
                  target={"_blank" as any}
                  className="btn"
                >
                  View CV <BsJournalBookmarkFill className="h-4 w-4 ml-2" />
                </a>
              )}
            </div>
          </div>

          {/* Technologies */}
          {/* <Technologies /> */}
        </div>
      </div>
    </>
  );
}
