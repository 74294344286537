import AOS from "aos";
import { createContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Contact from "./Components/Contact/Contact";
import Page404 from "./Components/Error/Page404";
import Home from "./Components/Home/Home";
import Portfolio from "./Components/Me/Portfolio";
import Services from "./Components/Services/Services";
import Navbar from "./Components/Shared/Navbar";
import PreLoader from "./Components/Shared/PreLoader";

export const InitializeContext = createContext({} as any);

export default function App() {
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  const [theme, setTheme] = useState<boolean>(false);
  useEffect(() => {
    setTheme(JSON.parse(window.localStorage.getItem("theme") || "false"));
  }, []);

  const handleThemeChange: () => void = () => {
    setTheme(!theme);
    window.localStorage.setItem("theme", JSON.stringify(!theme));
  };
  // console.log(loading);

  return (
    <div data-theme={theme ? "night" : "light"}>
      <InitializeContext.Provider
        value={{
          theme,
          handleThemeChange,
          loading,
        }}
      >
        <div>
          {loading ? (
            <PreLoader />
          ) : (
            <div>
              <Navbar />

              {/* All the routes in this*/}
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/me" element={<Portfolio />} />
                <Route path="/service" element={<Services />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="*" element={<Page404 />} />
              </Routes>
            </div>
          )}
        </div>
      </InitializeContext.Provider>
    </div>
  );
}
