import axios from "axios";
import { useEffect, useState } from "react";
import BackEnd from "./BackEnd";
import FrontEnd from "./FrontEnd";
import FullStack from "./FullStack";
export default function Projects() {
  const [categories, setCategories] = useState<any>([]);
  const [selectProjects, setSelectProjects] = useState<string>(
    "63a5d03870257ba9a087ad3c"
  );

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_LIVE_API_URL}/api/v1/categories/all`)
      .then((res) => setCategories(res.data.category));
  }, [selectProjects]);
  console.log(categories);

  return (
    <div data-aos="zoom-in-up">
      <div className="container mx-auto mb-10">
        <h1 className="text-center text-3xl m-5">Projects</h1>
        <div className="btn-group flex justify-center my-8">
          {categories.map((category: any) => {
            return (
              <button
                key={category._id}
                onClick={() => setSelectProjects(category._id)}
                className={`btn  ${
                  selectProjects === category._id
                    ? " btn-primary"
                    : "btn-outline"
                }`}
              >
                {category.type}
              </button>
            );
          })}
        </div>
        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 justify-items-center">
          {categories.map((category: any) => {
            <div
              className={`${
                category.projectCount === 0 ? "col-span-full" : ""
              } justify-self-center`}
              key={category._id}
            ></div>;
            if (category._id === selectProjects) {
              return category.type === "frontend" ? (
                <FrontEnd key={category._id} category={category._id} />
              ) : category.type === "backend" ? (
                <BackEnd key={category._id} category={category._id} />
              ) : (
                <FullStack key={category._id} category={category._id} />
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}
