import { useContext } from "react";
import { BallTriangle } from "react-loader-spinner";
import { InitializeContext } from "../../App";

export default function PreLoader() {
    const {theme} = useContext(InitializeContext)
    return (
        <div className="h-screen flex justify-center items-center backdrop-blur-[9xl]">
            <BallTriangle
                height={100}
                width={100}
                radius={5}
                color={
                    theme ? "#0ca6e9" : "#793ef9"
                }
                ariaLabel="ball-triangle-loading"
                visible={true}
            />
        </div>
    )
}
