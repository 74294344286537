import DOMPurify from "dompurify";
import ReactHtmlParser from "html-react-parser";
import React, { useContext, useState } from "react";
import { SiOpenbadges, SiXrp } from "react-icons/si";
import { InitializeContext } from "../../App";
import { FullStackProps } from "./projects.types";

const DynamicProject: React.FC<FullStackProps> = ({ projects }) => {
  const { theme } = useContext(InitializeContext);
  const themeClass = theme
    ? "bg-base-100 "
    : "bg-slate-50/60 backdrop-blur-2xl";
  const [expandedCardIndex, setExpandedCardIndex] = useState<number | null>(
    null
  );

  const handleReadMore = (index: number) => {
    setExpandedCardIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  // Helper function to truncate text
  const truncateText = (text: string, maxLength: number): string => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  return (
    <>
      {projects.length > 0 ? (
        projects.map((project, index) => (
          <div
            key={index}
            className={`card shadow-2xl p-3 rounded-xl ${themeClass}`}
            style={{ maxWidth: "400px" }}
          >
            <figure
              style={{
                height: "300px",
                width: "100%",
                backgroundSize: "cover",
              }}
            >
              <img
                src={project?.image}
                className="p-1 w-full"
                alt={project.name}
              />
            </figure>
            <div className="card-body">
              <h2 className="card-title">{project.name}</h2>
              <div>
                {expandedCardIndex === index
                  ? ReactHtmlParser(DOMPurify.sanitize(project.description))
                  : ReactHtmlParser(
                      truncateText(DOMPurify.sanitize(project.description), 100)
                    )}
              </div>
              {project.description.length > 100 && (
                <button
                  className="text-blue-500 mt-2"
                  onClick={() => handleReadMore(index)}
                >
                  {expandedCardIndex === index ? "Show Less" : "Read More"}
                </button>
              )}
              <div className="card-actions justify-end m-2 ">
                <a
                  href={project.code}
                  target="_blank"
                  rel="noreferrer"
                  className="badge badge-outline badge-primary hover:badge hover:badge-neural"
                >
                  <SiOpenbadges className="h-4 w-4 mr-1" />
                  fullstack
                </a>
                <a
                  href={project.liveLink}
                  target="_blank"
                  rel="noreferrer"
                  className="badge badge-outline badge-primary hover:badge hover:badge-neural"
                >
                  <SiXrp className="h-4 w-4 mr-1" />
                  Live
                </a>
              </div>

              {/* Technologies */}
              <div className="flex flex-wrap gap-2 mt-2">
                {project?.technologies.map((technology, index) => (
                  <div
                    key={index}
                    className="badge badge-outline badge-secondary"
                  >
                    {technology}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))
      ) : (
        <>
          <div className="flex">
            <div className="bg-white bg-opacity-40 p-8 rounded-md shadow-lg backdrop-blur-md text-center">
              <p className="text-2xl font-bold mb-4">
                No projects are available in this category. Please check back
                later.
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DynamicProject;
