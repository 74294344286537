import DynamicProject from "./DynamicProject";
import ProjectSkeleton from "./ProjectSkeleton";
import useProjectData from "./useProjectData";
export default function FullStack(props: any) {
  const categoryid = props.category;
  const { projects, loading } = useProjectData(categoryid);

  return (
    <>
      {!loading ? <DynamicProject projects={projects} /> : <ProjectSkeleton />}
    </>
  );
}
