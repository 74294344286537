import { useContext } from "react";
import { ImCss3, ImHtmlFive } from "react-icons/im";
import { IoLogoJavascript } from "react-icons/io5";
import { RiReactjsLine } from "react-icons/ri";
import {
  SiAmazonaws,
  SiBootstrap,
  SiDocker,
  SiExpress,
  SiFirebase,
  SiGithub,
  SiGithubactions,
  SiHeroku,
  SiJsonwebtokens,
  SiMongodb,
  SiMongoose,
  SiMui,
  SiNetlify,
  SiNextdotjs,
  SiNginx,
  SiNodedotjs,
  SiNpm,
  SiPostgresql,
  SiPrisma,
  SiReactquery,
  SiRedis,
  SiRedux,
  SiSass,
  SiStripe,
  SiTailwindcss,
  SiTypescript,
  SiVercel,
} from "react-icons/si";
import { InitializeContext } from "../../App";
type technologies = {
  name: string;
  icon: any;
};
export default function Technologies() {
  let frontEndTechnologies: technologies[] = [
    {
      name: "HTML",
      icon: <ImHtmlFive />,
    },
    {
      name: "CSS",
      icon: <ImCss3 />,
    },
    {
      name: "JavaScript",
      icon: <IoLogoJavascript />,
    },
    {
      name: "React",
      icon: <RiReactjsLine />,
    },
    {
      name: "TypeScript",
      icon: <SiTypescript />,
    },
    {
      name: "TailwindCSS",
      icon: <SiTailwindcss />,
    },
    {
      name: "Bootstrap",
      icon: <SiBootstrap />,
    },
    {
      name: "Material-UI",
      icon: <SiMui />,
    },
    {
      name: "Sass",
      icon: <SiSass />,
    },
    {
      name: "Git Control",
      icon: <SiGithubactions />,
    },
    {
      name: "Next.js",
      icon: <SiNextdotjs />,
    },
    {
      name: "React Query",
      icon: <SiReactquery />,
    },
    {
      name: "Redux",
      icon: <SiRedux />,
    },
  ];
  let backEndTechnologies: technologies[] = [
    {
      name: "NodeJS",
      icon: <SiNodedotjs />,
    },
    {
      name: "ExpressJS",
      icon: <SiExpress />,
    },
    {
      name: "JWT",
      icon: <SiJsonwebtokens />,
    },
    {
      name: "MongoDB",
      icon: <SiMongodb />,
    },
    {
      name: "Firebase",
      icon: <SiFirebase />,
    },
    {
      name: "Stripe",
      icon: <SiStripe />,
    },
    {
      name: "Heroku",
      icon: <SiHeroku />,
    },
    {
      name: "Netlify",
      icon: <SiNetlify />,
    },
    {
      name: "Vercel",
      icon: <SiVercel />,
    },
    {
      name: "Github",
      icon: <SiGithub />,
    },
    {
      name: "NPM",
      icon: <SiNpm />,
    },
    {
      name: "Redis",
      icon: <SiRedis />,
    },
    {
      name: "Prisma",
      icon: <SiPrisma />,
    },

    {
      name: "Mongoosse",
      icon: <SiMongoose />,
    },
    {
      name: "Postgresql",
      icon: <SiPostgresql />,
    },
    {
      name: "Docker",
      icon: <SiDocker />,
    },
    {
      name: "AWS",
      icon: <SiAmazonaws />,
    },
    {
      name: "Github actions",
      icon: <SiGithubactions />,
    },
    {
      name: "Nginx",
      icon: <SiNginx />,
    },
  ];
  const { theme } = useContext(InitializeContext);
  const themeClass = theme
    ? "bg-base-100 "
    : "bg-slate-50/60 backdrop-blur-2xl";
  return (
    <div className="container mx-auto sm:flex-wrap md:flex">
      <div className="flex flex-col p-5 md:w-1/2">
        <div className={themeClass + "my-2 h-full shadow-xl p-5 rounded"}>
          <h1 className="text-center text-2xl mt-5 font-bold underline">
            Front-end
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-2 md:p-5">
            {frontEndTechnologies.map((frontendTechnology, index) => (
              <div key={index} className="flex items-center">
                {frontendTechnology.icon}
                <p className="ml-2">{frontendTechnology.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="flex flex-col p-5 md:w-1/2">
        <div className={themeClass + "my-2 h-full shadow-xl p-5  rounded"}>
          <h1 className="text-center text-2xl mt-5 font-bold underline">
            Backend
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-2 md:p-5">
            {backEndTechnologies.map((backendTechnology, index) => (
              <div key={index} className="flex items-center">
                {backendTechnology.icon}
                <p className="ml-2">{backendTechnology.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
