import { useContext } from "react";
import { BsFacebook, BsInstagram, BsTwitter } from "react-icons/bs";
import { InitializeContext } from "../../App";
import footerLightBg from "./wave1.svg";
import footerdarkBg from "./wave2.svg";

export default function Footer() {
  const { theme } = useContext(InitializeContext);
  const footerBg = theme ? footerdarkBg : footerLightBg;
  return (
    <div
      style={{
        backgroundImage: `url(${footerBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        // marginTop: '-15px',
        overflow: "hidden",
        opacity: "0.8",
        fontStyle: "bold",
      }}
    >
      <footer className="footer footer-center p-10 mt-20">
        <div>
          <p className="font-bold">
            <span className="text-2xl">Nahid Hassan</span> Bulbul
          </p>
          <p>Copyright © 2024 - All right reserved</p>
        </div>
        <div>
          <div className="grid grid-flow-col gap-4">
            <BsFacebook className="w-6 h-6" />
            <BsTwitter className="w-6 h-6" />
            <BsInstagram className="w-6 h-6" />
          </div>
        </div>
      </footer>
    </div>
  );
}
