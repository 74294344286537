import { Prism } from '@mantine/prism';

import { useContext } from "react";
import { Button, CodeMockup } from 'react-daisyui';
import { TbBrandTelegram } from "react-icons/tb";
import { InitializeContext } from "../../App";
import './Hero.css';
export default function Heros() {
  const { theme } = useContext(InitializeContext);

  const codeString: string =
    `
  interface dev {
    name: string;
    email: string;
    age?: number;
    address: string;
    hobby?: object;
  }
  export default function Me() {
    const me: dev = {
        name: "Nahid Hassan Bulbul",
        email: "nahidhassanbulbul@gmail.com",
        address: "Dhaka, Bangladesh",
        hobby: ['coding', 'travelling']
    }
    return (
        <>
            <h1>Hi I'm <span>{me.name}</span> . I'm from {me.address}.</h1>
            <p>Email:{me.email}</p>
        </>
    )
  }
  `
  // type highlightLines = {
  //   color: string;
  //   label: string;
  // }
  // const deleted: highlightLines = { color: 'red', label: '-' };
  // const added: highlightLines = { color: 'green', label: '+' };

  return (
    <  >
      <div className={
        theme ? 'heroDarkBg' : 'heroWhiteBg'
      }
        data-aos="fade-down"
      >
        <div className={
          theme ? 'heroDarkPattern' : 'heroLightPattern'
        }>
          <div className=" md:flex md:justify-around md:items-center  h-screen p-5">
            <div className="text-left">
              <h1 className="text-4xl md:text-6xl mt-2 mb-2">Welcome to </h1>
              <h1 className="text-3xl md:text-5xl mt-2  ">My Personal <span className="underline  md:no-underline circle-sketch-highlight">
                Portfolio
              </span> </h1>
              <p className="text-xl font-semibold mt-5 mb-3 ">I'm <span className="underline text-2xl">Nahid</span> Hassan Bulbul, a self-taught web developer from Bangladesh. </p>
              <Button variant='outline' color={
                theme ? 'info' : 'primary'
              } className=" mt-5 text-xl"
              > <TbBrandTelegram className="h-6 w-6 mr-1" /> Hire Me</Button>
            </div>
            <CodeMockup className="bg-transparent" >
              <Prism language="tsx"
                withLineNumbers
                noCopy
                // highlightLines={{
                //   3: deleted,
                //   4: deleted,
                //   5: deleted,
                //   7: added,
                //   8: added,
                //   9: added,
                // }}
                colorScheme={
                  theme ? "dark" : "light"
                }
              >{codeString}</Prism>
            </CodeMockup>

          </div>
        </div>
      </div>
    </>
  )
}
