// useProjectData.ts
import axios from "axios";
import { useEffect, useState } from "react";
import { IProject } from "./projects.types";

const useProjectData = (category: string) => {
  const [projects, setProjects] = useState<IProject[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_LIVE_API_URL}/api/v1/projects/project?category=${category}`
      )
      .then((res) => {
        setProjects(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching project data:", error);
        setLoading(false);
      });
  }, [category]);

  return { projects, loading };
};

export default useProjectData;
