import { useContext } from "react";
import { InitializeContext } from "../../App";
import About from "../About/About";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import Hero from "../Hero/Hero";
import Technologies from "../Me/Technologies";
import Projects from "../Projects/Projects";

export default function Home() {
  const { theme } = useContext(InitializeContext);
  const themeClass = theme ? "bg-night" : "bg-gray-200";
  return (
    <div className="-mt-5">
      <Hero />
      <div className={themeClass}>
        <About />
        <Projects />
        <Technologies />
        <Contact />
        <Footer />
      </div>
    </div>
  );
}
