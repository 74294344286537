import dev from "../../assets/dev.webp";
export default function About() {
  return (
    <>
      <div
        data-aos="fade-down"
        className="flex flex-wrap  overflow-hidden items-center p-10 mt-0 md:-mt-32"
      >
        <div className="flex justify-center overflow-hidden w-full md:w-1/3 order-last md:order-first">
          <img
            className="max-w-sm md:max-w-md mt-16"
            src={dev}
            alt="Nahid Hassan Bulbul"
          />
        </div>
        <div className=" w-full overflow-hidden md:w-2/3 mt-64  md:mt-0">
          <h1 className="text-5xl underline underline-offset-auto mb-2">
            Career Objectives!
          </h1>
          <p className="text-xl mt-5 mb-3 ">
            Quality-focused and results-driven professional seeking a position
            as a web developer with{" "}
            <span className="font-bold">JavaScript</span> &
            <span className="font-bold">Typescript</span> Juice where I can use
            my two years of experience as a web developer to enhance the user
            experience.
          </p>
        </div>
      </div>
    </>
  );
}
