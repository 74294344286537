import ContentLoader from "react-content-loader";

export default function ProjectSkeleton(props: any) {
  return (
    <>
      <ContentLoader
        speed={2}
        width={400}
        height={460}
        viewBox="0 0 400 460"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <rect x="18" y="13" rx="10" ry="10" width="311" height="266" />
        <rect x="18" y="290" rx="0" ry="0" width="189" height="36" />
        <rect x="114" y="334" rx="0" ry="0" width="15" height="5" />
        <rect x="1" y="344" rx="0" ry="0" width="2" height="1" />
        <rect x="18" y="332" rx="3" ry="3" width="302" height="52" />
        <rect x="127" y="391" rx="3" ry="3" width="56" height="16" />
        <rect x="196" y="392" rx="3" ry="3" width="56" height="15" />
        <rect x="261" y="393" rx="3" ry="3" width="56" height="14" />
        <rect x="25" y="434" rx="8" ry="8" width="61" height="17" />
        <rect x="97" y="434" rx="8" ry="8" width="61" height="17" />
        <rect x="176" y="434" rx="8" ry="8" width="61" height="17" />
        <rect x="251" y="434" rx="8" ry="8" width="61" height="17" />
      </ContentLoader>
      <ContentLoader
        speed={2}
        width={400}
        height={460}
        viewBox="0 0 400 460"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <rect x="18" y="13" rx="10" ry="10" width="311" height="266" />
        <rect x="18" y="290" rx="0" ry="0" width="189" height="36" />
        <rect x="114" y="334" rx="0" ry="0" width="15" height="5" />
        <rect x="1" y="344" rx="0" ry="0" width="2" height="1" />
        <rect x="18" y="332" rx="3" ry="3" width="302" height="52" />
        <rect x="127" y="391" rx="3" ry="3" width="56" height="16" />
        <rect x="196" y="392" rx="3" ry="3" width="56" height="15" />
        <rect x="261" y="393" rx="3" ry="3" width="56" height="14" />
        <rect x="25" y="434" rx="8" ry="8" width="61" height="17" />
        <rect x="97" y="434" rx="8" ry="8" width="61" height="17" />
        <rect x="176" y="434" rx="8" ry="8" width="61" height="17" />
        <rect x="251" y="434" rx="8" ry="8" width="61" height="17" />
      </ContentLoader>
      <ContentLoader
        speed={2}
        width={400}
        height={460}
        viewBox="0 0 400 460"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <rect x="18" y="13" rx="10" ry="10" width="311" height="266" />
        <rect x="18" y="290" rx="0" ry="0" width="189" height="36" />
        <rect x="114" y="334" rx="0" ry="0" width="15" height="5" />
        <rect x="1" y="344" rx="0" ry="0" width="2" height="1" />
        <rect x="18" y="332" rx="3" ry="3" width="302" height="52" />
        <rect x="127" y="391" rx="3" ry="3" width="56" height="16" />
        <rect x="196" y="392" rx="3" ry="3" width="56" height="15" />
        <rect x="261" y="393" rx="3" ry="3" width="56" height="14" />
        <rect x="25" y="434" rx="8" ry="8" width="61" height="17" />
        <rect x="97" y="434" rx="8" ry="8" width="61" height="17" />
        <rect x="176" y="434" rx="8" ry="8" width="61" height="17" />
        <rect x="251" y="434" rx="8" ry="8" width="61" height="17" />
      </ContentLoader>
    </>
  );
}
