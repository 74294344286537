export default function Contact() {
  return (
    <div className="container mx-auto mt-5">
      <div className="text-center">
        <h1 className="text-2xl font-bold">Get in touch with me</h1>
      </div>
      <div className="flex flex-col w-full lg:flex-row">
        <div className="grid flex-grow py-6 card rounded-box place-items-center">
          {/* 1st part */}
          <div className="flex flex-col items-center justify-center">
            <div>
              <h1 className="tesxt-2xl font-bold">Basic Details</h1>
              <div>
                <p className="text-base">
                  Name: <span className="font-bold">Nahid Hassan Bulbul</span>
                </p>
                <p className="text-base">
                  Age: <span className="font-bold">21</span>
                </p>
                <p className="text-base">
                  Email:{" "}
                  <span className="font-bold">
                    <a href="mailto:nahidhassanbulbul@gmail.com">
                      nahidhassanbulbul@gmail.com
                    </a>
                  </span>
                </p>
                <p className="text-base">
                  Phone:{" "}
                  <span className="font-bold">
                    <a href="tel:+8801628-719-587">+8801628-719-587</a>
                  </span>
                </p>
                <p className="text-base">
                  Address:{" "}
                  <span className="font-bold">
                    <a
                      href="https://goo.gl/maps/vTXGsTvC19sA7EnV8"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Dhaka-1230
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="divider lg:divider-horizontal"></div>
        <div className="grid flex-grow  card  rounded-box place-items-center">
          {/* 2nd part */}
          <div>
            <h1 className="text-2xl font-bold">Contact form</h1>
          </div>
        </div>
      </div>
    </div>
  );
}
